import {AppBar,Grid,Toolbar,Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "linear-gradient(to right, #C3FF00, #FFFF00)",
        color: theme.palette.primary.text
    },
    toolBar: {
        paddingLeft: 0
    }
}));

export default function Header() {

    const classes = useStyles();

    return <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
            <img style={{height: "4rem"}} alt={"home"} src={require("../images/logo-header.png")}/>
            <div style={{flexGrow: 1}}/>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item>
                    <Typography>POWERED BY</Typography>
                </Grid>
                <Grid item>
                    <img style={{padding: "0rem 1.5rem"}} alt={"logoNextome"} src={require("../images/Nextome-logo-esteso.svg").default}/>
                </Grid>
                <Grid item>
                    <img style={{height: "1.2rem"}} alt={"lendlease"} src={require("../images/Lendlease-esteso.png")}/>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
}