import React,{useState} from "react";
import Header from "./components/Header";
import Cards from "./components/Cards";
import Map from "./components/Map";
import {TokenManager} from "./services/TokenManager";
import {
  changePassword,
  forgottenPasswordRequest,
  forgottenPasswordRestore,
  login,
  refreshToken
} from "./services/UserManager";
import {NTMLayout} from "react-layout-components/dist";
import ChartContainer from "./components/ChartContainer";
import {makeStyles} from "@mui/styles";
import {Card} from "@mui/material";
import {SENSOR1ID} from "./constants/sensorsId";

export const UserContext = React.createContext(null);

let tokenManager = new TokenManager();

export default function App() {

  let [user, setUser] = useState({status:{ready:false}, data:{}})

  return <UserContext.Provider value={user}>
    <AppLayout user={user} setUser={setUser}/>
  </UserContext.Provider>
}

const useStyles = makeStyles((theme) => ( {
  card : {
    backgroundColor: "#fff",
    borderRadius : "16px",
    marginTop: "1rem",
    padding: "1rem"
  },
}));

function AppLayout({user, setUser}){

  let [selectedSensor, setSelectedSensor] = useState(SENSOR1ID)
  const classes = useStyles();

  const theme = {
    palette : {
      primary : {
        main : '#6ADD3E',
        light: '#C3FF00',
        text: '#000000',
        contrastText: '#fff',
        dark: '#12313A'
      },
      secondary : {
        main : '#12313A',
      },
      variables: {
        nuvapIndex: '#1CA8F8',
        carbonDioxide: '#69E3CC',
        organicCompound: '#54B6A3',
        pm1: '#DBD4C7',
        pm10: '#C6B699',
        pm25: '#968056',
        relativeHumidity: '#52B8D6',
        temperature: '#72DFFF',
        peopleTraffic: '#10303B'
      }
    }
  }

  return (
      <NTMLayout
          theme={theme}
          user={user} setUser={setUser}
          login={login} refreshToken={refreshToken} changePassword={changePassword}
          forgottenPasswordRequest={forgottenPasswordRequest} forgottenPasswordRestore={forgottenPasswordRestore}
          tokenManager={tokenManager} menu={[]}
          header={<Header/>} sidebar={<div/>}
      >
        <Cards/>
        <Card className={classes.card}>
          <Map selectedSensor={selectedSensor} setSelectedSensor={setSelectedSensor}/>
        </Card>
        <Card className={classes.card}>
          <ChartContainer selectedSensor={selectedSensor}/>
        </Card>
      </NTMLayout>
  )
}