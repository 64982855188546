import React from "react";

import {Grid,Typography} from "@mui/material";

import markerIcon from "../../../images/on-map-marker-select.svg";

import BasePopup from "./BasePopup";
import TextBoxForPopup from "./TextBoxForPopup";
import {withStyles} from "@mui/styles";
import {useGetPoiAnalytics} from "../../../services/ContentManager";

const BoldTypography = withStyles(() => ({
    root: {
        paddingLeft: "1rem",
        paddingBottom: "0.5rem",
        fontWeight : 'bold'
    },
}))(Typography);

export default function MarkerPopup ({marker, popupPosition}) {

    let {poiAnalytics} = useGetPoiAnalytics(marker.id);

    const averageGeofencingHours = Math.floor(poiAnalytics.averageGeofencingTime/60/60)
    const averageGeofencingMins = Math.floor(poiAnalytics.averageGeofencingTime/60)%60

    return(
        <BasePopup popupPosition={popupPosition} header={
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                    <img alt={marker.id} src={markerIcon} style={{height: "2.5rem"}}/>
                </Grid>
                <Grid item>
                    <BoldTypography>
                        {marker?.descriptions[0].name}
                    </BoldTypography>
                </Grid>
            </Grid>
        }>
            <TextBoxForPopup
                element={`${poiAnalytics.navigationCount || 0} ${poiAnalytics.navigationCount === 1 ? "VOLTA" : "VOLTE"}`}
                label="Navigazione"
            />
            <TextBoxForPopup
                element={`${averageGeofencingHours || 0} ${averageGeofencingHours === 1 ? "ora" : "ore"} ${averageGeofencingMins || 0} ${averageGeofencingMins === 1 ? "minuto" : "minuti"}`}
                label="Tempo di permanenza medio"
            />
            <TextBoxForPopup
                element={`${poiAnalytics.infoCount || 0} ${poiAnalytics.infoCount === 1 ? "VOLTA" : "VOLTE"}`}
                label="Apertura scheda"
            />
        </BasePopup>
    );
}