import {Box,Checkbox,FormControl,FormControlLabel,FormGroup,Grid,Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useState} from "react";
import {FilterAndSearchBar} from "react-layout-components/dist";
import Chart from "./Chart";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "1.5rem 0"
    },
    buttonContainer: {
        marginLeft: "2rem"
    },
    nuvapIndexCheckbox: {
        color: theme.palette.variables.nuvapIndex,
        '&.Mui-checked': {
            color: theme.palette.variables.nuvapIndex,
        },
    },
    carbonDioxideCheckbox: {
        color: theme.palette.variables.carbonDioxide,
        '&.Mui-checked': {
            color: theme.palette.variables.carbonDioxide,
        },
    },
    organicCompoundCheckbox: {
        color: theme.palette.variables.organicCompound,
        '&.Mui-checked': {
            color: theme.palette.variables.organicCompound,
        },
    },
    pm1Checkbox: {
        color: theme.palette.variables.pm1,
        '&.Mui-checked': {
            color: theme.palette.variables.pm1,
        },
    },
    pm10Checkbox: {
        color: theme.palette.variables.pm10,
        '&.Mui-checked': {
            color: theme.palette.variables.pm1,
        },
    },
    pm25Checkbox: {
        color: theme.palette.variables.pm25,
        '&.Mui-checked': {
            color: theme.palette.variables.pm25,
        },
    },
    relativeHumidityCheckbox: {
        color: theme.palette.variables.relativeHumidity,
        '&.Mui-checked': {
            color: theme.palette.variables.relativeHumidity,
        },
    },
    temperatureCheckbox: {
        color: theme.palette.variables.temperature,
        '&.Mui-checked': {
            color: theme.palette.variables.temperature,
        },
    },
    peopleTrafficCheckbox: {
        color: theme.palette.variables.peopleTraffic,
        '&.Mui-checked': {
            color: theme.palette.variables.peopleTraffic,
        },
    }
}));

export default function ChartContainer({selectedSensor}) {

    const classes = useStyles();
    let [selectedFilter, setSelectedFilter] = useState("today");
    let [selectedAxes, setSelectedAxes] = useState({nuvapIndex : true})

    const filters = [
        {
            id: "today",
            name: "OGGI",
            daysToSubtract: 1
        },
        {
            id: "last7",
            name: "ULTIMI 7 GIORNI",
            daysToSubtract: 7
        },
        {
            id: "last30",
            name: "ULTIMI 30 GIORNI",
            daysToSubtract: 30
        }
    ]

    const selectableAxes = [
        {
            label: "NUVAP INDEX",
            name: "nuvapIndex",
            className: classes.nuvapIndexCheckbox
        },{
            label: "Carbon Dioxide",
            name: "carbonDioxide",
            className: classes.carbonDioxideCheckbox
        },{
            label: "Total Volatile Organic Compound",
            name: "organicCompound",
            className: classes.organicCompoundCheckbox
        },{
            label: "PM 1",
            name: "pm1",
            className: classes.pm1Checkbox
        },{
            label: "PM 10",
            name: "pm10",
            className: classes.pm10Checkbox
        },{
            label: "PM 2,5",
            name: "pm25",
            className: classes.pm25Checkbox
        },{
            label: "Relative Humidity",
            name: "relativeHumidity",
            className: classes.relativeHumidityCheckbox
        },{
            label: "Temperature",
            name: "temperature",
            className: classes.temperatureCheckbox
        },{
            label: "Traffico persone",
            name: "peopleTraffic",
            className: classes.peopleTrafficCheckbox
        }
    ]

    const handleChangeAxes = (event) => {
        setSelectedAxes({
            ...selectedAxes,
            [ event.target.name ] : event.target.checked,
        })
    }

    return <Grid className={classes.root}>
        <Grid container spacing={2} className={classes.buttonContainer}>
            <FilterAndSearchBar
                filters={filters}
                onFilter={setSelectedFilter}
                selected={selectedFilter}
            />
        </Grid>
        <Grid container spacing={2} sx={{marginTop: "1.5rem"}}>
            <Grid item xs={8}>
                <Chart
                    startTimestamp={(new Date(2023, 8, 30, 23, 59, 59)).getTime() - filters.find(f => f.id === selectedFilter).daysToSubtract * 24 * 60 * 60 * 1000}
                    endTimestamp={(new Date(2023, 8, 30, 23, 59, 59)).getTime()}
                    selectedAxes={selectedAxes} selectedSensor={selectedSensor}/>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormGroup>
                            {
                                selectableAxes?.map(s => {
                                    return <FormControlLabel
                                        control={<Checkbox
                                            className={s.className}
                                            checked={selectedAxes[s.name]}
                                            onChange={handleChangeAxes}
                                            name={s.name}
                                        />}
                                        label={<Typography fontWeight="bold">
                                            {s.label}
                                        </Typography>}
                                    />
                                })
                            }
                        </FormGroup>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    </Grid>
}