import {getErrorMessage,post} from "./Client";
import {TokenManager} from "./TokenManager";
let tokenManager = new TokenManager();

export function login(email, password) {
    return new Promise((resolve, reject) => {
        post(`developers/login`, {body: {email, password}})
            .then((data) => {
                const { developer, ...token } = data;
                tokenManager.save(token)
                resolve(data)
                console.log("onLogin, ",data)
            })
            .catch(e => {
                let message = getErrorMessage(e)
                reject(message)
            });
    })
}

export function refreshToken() {
    return new Promise((resolve, reject) => {
        let token = tokenManager.getAllTokens()
        if(token && token.refreshToken) {
            return post(`developers/refresh`, {body: token.refreshToken}).then((data) => {
                token.accessToken=data.accessToken
                tokenManager.save(token)
                resolve(data)
            }).catch(e => reject(getErrorMessage(e)))
        } else {
            reject()
        }
    })

}

export function changePassword(email, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        post(`developers/password/challenge`, {body: { email, oldPassword, newPassword}})
            .then((data) => {
                const { developer, ...token } = data;
                tokenManager.save(token)
                resolve(data)
            })
            .catch(e => {
                let message = getErrorMessage(e)
                reject(message)
            });
    })
}

export function forgottenPasswordRequest(email) {
    return new Promise((resolve, reject) => {
        post(`developers/password/forgotten/request`,{params : {developerEmail: email}})
            .then((data) => resolve(data))
            .catch(e => reject(getErrorMessage(e)))
    })
}

export function forgottenPasswordRestore(confirmationCode, email, newPassword) {
    return new Promise((resolve, reject) => {
        post(`developers/password/forgotten/restore`,{body: {confirmationCode, email, newPassword}})
            .then((data) => resolve(data))
            .catch(e => reject(getErrorMessage(e)))
    })
}