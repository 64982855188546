import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useGetMeasurements} from "../services/ContentManager";
import {useTheme} from "@mui/material";
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function Chart({startTimestamp, endTimestamp, selectedAxes, selectedSensor}) {

    const theme = useTheme();
    let {measurements} = useGetMeasurements(startTimestamp, endTimestamp)

    const getAxisOptions = (display, color, title) => {
        return  {
            type: 'linear',
            display: display || false,
            position: 'left',
            border: {
                color: color
            },
            ticks: {
                color: color
            },
            title: {
                display: true,
                text: title,
                align: "center",
                font: {
                    weight : 'bold',
                    size: 15
                },
                color: color,
                padding: {top: 20, left: 0, right: 0, bottom: 0}
            }
        }
    }

    const labels = measurements.filter(m => m.deviceId === selectedSensor).map(m =>
        dayjs(m.timestamp, "x").format("DD/MM/YY [h] HH:mm")
    );

    const datasetsValues = [
        {axisName: "nuvapIndex", variableName: "nuvapIndex", color: theme.palette.variables.nuvapIndex, axisId: "y"},
        {axisName: "carbonDioxide", variableName: "co2", color: theme.palette.variables.carbonDioxide, axisId: "y1"},
        {axisName: "organicCompound", variableName: "voc", color: theme.palette.variables.organicCompound, axisId: "y2"},
        {axisName: "pm1", variableName: "pm1", color: theme.palette.variables.pm1, axisId: "y3"},
        {axisName: "pm10", variableName: "pm10", color: theme.palette.variables.pm10, axisId: "y4"},
        {axisName: "pm25", variableName: "pm25", color: theme.palette.variables.pm25, axisId: "y5"},
        {axisName: "relativeHumidity", variableName: "humidity", color: theme.palette.variables.relativeHumidity, axisId: "y6"},
        {axisName: "temperature", variableName: "temperature", color: theme.palette.variables.temperature, axisId: "y7"},
        {axisName: "peopleTraffic", variableName: "peopleTraffic", color: theme.palette.variables.peopleTraffic, axisId: "y8"},
    ]

    const data = {
        labels,
        datasets: [
            ...datasetsValues.map((dv) => {
                if(selectedAxes[dv.axisName]) return {
                    data: measurements.filter(m => m.deviceId === selectedSensor).map(m => m[dv.variableName]),
                    borderColor:  dv.color,
                    backgroundColor:  dv.color,
                    yAxisID: dv.axisId,
                }
                else return {}
            })
        ],
    };

    let noAxesSelected = true
    Object.entries(selectedAxes).map(([key, value]) => {
        if(value) noAxesSelected = false
    })

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10
                }
            },
            y: {...getAxisOptions(selectedAxes.nuvapIndex, theme.palette.variables.nuvapIndex, "")},
            y1: {...getAxisOptions(selectedAxes.carbonDioxide, theme.palette.variables.carbonDioxide, "ppm")},
            y2: {...getAxisOptions(selectedAxes.organicCompound, theme.palette.variables.organicCompound, "ppB")},
            y3: {...getAxisOptions(selectedAxes.pm1, theme.palette.variables.pm1, "μg/m^3")},
            y4: {...getAxisOptions(selectedAxes.pm10, theme.palette.variables.pm10, "μg/m^3")},
            y5: {...getAxisOptions(selectedAxes.pm25, theme.palette.variables.pm25, "μg/m^3")},
            y6: {...getAxisOptions(selectedAxes.relativeHumidity, theme.palette.variables.relativeHumidity, "%")},
            y7: {...getAxisOptions(selectedAxes.temperature, theme.palette.variables.temperature, "°C")},
            y8: {...getAxisOptions(selectedAxes.peopleTraffic, theme.palette.variables.peopleTraffic, "")},
            y9: {...getAxisOptions(noAxesSelected, "#CCC", "")}
        },
        plugins: {
            legend : {
                display: false
            }
        }
    }

    return <Line options={options} data={data}/>
}