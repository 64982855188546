import React from "react";
import URLImage from "./URLImage";

const markerImage = require("../../images/on-map-marker.svg").default;
const markerImageSelected = require("../../images/on-map-marker-select.svg").default;
const nextomeMarkerImage = require("../../images/on-map-marker-nextome.svg").default;
const nextomeMarkerImageSelected = require("../../images/on-map-marker-nextome-select.svg").default;

export default function MarkerImage({marker, onClick, selected, scale, ratio}) {

	const src = marker.id+"" === process.env.REACT_APP_NEXTOME_POI_ID ? nextomeMarkerImage : markerImage
	const srcSelected = marker.id+"" === process.env.REACT_APP_NEXTOME_POI_ID ? nextomeMarkerImageSelected : markerImageSelected  //todo da aggiornare con il pin nextome selezionato

	return <URLImage
		src={selected ? srcSelected : src}
		scale={scale}
		imgProps={{
			id: marker.id,
			x: marker.x * ratio,
			y: marker.y * ratio,
			onClick,
			centered: true
		}}
	/>;

}
