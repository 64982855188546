import {useEffect,useState} from "react";
import MapView from "./mapview/MapView";
import {Grid, Typography} from "@mui/material";
import {makeStyles,withStyles} from "@mui/styles";
import MarkerPopup from "./mapview/popup/MarkerPopup";
import {useGetNuvapSnapshot,useGetPoi} from "../services/ContentManager";
import {SENSOR1ID,SENSOR2ID} from "../constants/sensorsId";

const useStyles = makeStyles((theme) => ( {
    nuvapIndex : {
        color: theme.palette.variables.nuvapIndex
    },
    carbonDioxide : {
        color: theme.palette.variables.carbonDioxide
    },
    organicCompound : {
        color: theme.palette.variables.organicCompound
    },
    pm1 : {
        color: theme.palette.variables.pm1
    },
    pm10 : {
        color: theme.palette.variables.pm10
    },
    pm25 : {
        color: theme.palette.variables.pm25
    },
    relativeHumidity : {
        color: theme.palette.variables.relativeHumidity
    },
    temperature : {
        color: theme.palette.variables.temperature
    }
}));

const BoldTypography = withStyles((theme) => ({
    root: {
        fontWeight : 'bold'
    },
}))(Typography);

export default function Map({selectedSensor, setSelectedSensor}){

    const classes = useStyles();
    let [popup, setPopup] = useState({});
    let {snapshot} = useGetNuvapSnapshot();
    let {poi} = useGetPoi()

    const [nuvapSensors, setNuvapSensors] = useState([])

    useEffect(() => {
        setNuvapSensors([
            {
                deviceId: SENSOR1ID,
                x: 1258.077696592025,
                y: 629.493044533657,
                ...snapshot.find(s => s.deviceId === SENSOR1ID)
            },
            {
                deviceId: SENSOR2ID,
                x: 1024.9896672946902,
                y: 1191.1586219774065,
                ...snapshot.find(s => s.deviceId === SENSOR2ID)
            }
        ])
    }, [snapshot])

    const onMarkerClick = (e, popupPosition) => {
        let marker = poi.find((m) => m.id === e.id);
        let newPopup = <MarkerPopup
            marker={marker}
            popupPosition={popupPosition}
        />
        setPopup({container: newPopup, id: e.id});
    }

    const selectedSnapshotDevice = nuvapSensors.find(s => s.deviceId === selectedSensor) || {}

    return <Grid sx={{padding: "1rem 0px"}}>
        <MapView
            markers={poi}
            onBackgroundClick={() => setPopup({})}
            onMarkerClick={onMarkerClick}
            nuvapSensors={nuvapSensors.map(ns => ns.deviceId === selectedSensor ? {selected: true, ...ns} : ns)}
            onNuvapSensorClick={newId => setSelectedSensor(newId)}
            popup={popup.container}
        >
            <Grid container direction="column" justifyContent="center" spacing={3} sx={{padding: "2.5rem"}}>
                <Grid item>
                    <BoldTypography className={classes.nuvapIndex}>NUVAP INDEX</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.nuvapIndex}</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.carbonDioxide}>Carbon Dioxide</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.co2}ppm</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.organicCompound}>Total Volatile Organic Compound</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.voc} ppB</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.pm1}>PM 1</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.pm1} μg/m^3</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.pm10}>PM 10</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.pm10} μg/m^3</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.pm25}>PM 2,5</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.pm25} μg/m^3</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.relativeHumidity}>Relative Humidity</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.humidity}%</BoldTypography>
                </Grid>
                <Grid item>
                    <BoldTypography className={classes.temperature}>Temperature</BoldTypography>
                    <BoldTypography>{selectedSnapshotDevice.temperature} °C</BoldTypography>
                </Grid>
            </Grid>
        </MapView>
    </Grid>

}