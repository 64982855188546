import React,{useEffect,useRef,useState} from "react";
import {Stage,Layer} from 'react-konva';
import PropTypes from "prop-types";

import URLImage from "./URLImage";
import MarkerImage from "./MarkerImage";
import SensorImage from "./SensorImage";

export default function IndoorMap({	  map,containerWidth, containerHeight,
	                                  refElem,mapImage,onBackgroundClick,onWheel,scale,
	                                  markers, onMarkerClick,
									  nuvapSensors, onNuvapSensorClick
                                  }) {

	const backgroundRef = useRef();
	const markerLayer = useRef();
	const nuvapSensorsLayer = useRef();

	let [canvas, setCanvas] = useState({});
	let [background, setBackground] = useState({});
	let [selected, setSelected] = useState({});
	let [cursorPosition, setCursorPosition] = useState();

	useEffect(() => {
		if (containerWidth && canvas.width !== containerWidth) {
			setBackground({})
			let newCanvas = Object.assign({}, canvas);
			newCanvas.width = containerWidth;
			newCanvas.height = containerHeight || 600;
			setCanvas(newCanvas)
		}
	}, [containerWidth]);

	const findPositionOnMap = () => {
		let scale = refElem.current.scaleX();
		let {x,y} = refElem.current.getPointerPosition();
		let translate = {
			x:refElem.current.x(),
			y:refElem.current.y()
		};
		return {
			x: (x - translate.x ) / scale / background.ratio,
			y: (y - translate.y ) / scale / background.ratio,
		}
	};

	function setBackgroundImage(backgroundImage) {
		let h = backgroundImage.height;
		let w = backgroundImage.width;
		let ratio = h / w;

		let background = {
			original: {h, w}
		};
		if (h > w) {
			background.height = canvas.height;
			background.width = canvas.height / ratio;
			background.ratio = canvas.height / h;
		} else {
			background.height = ratio * canvas.width;
			background.width = canvas.width;
			background.ratio = canvas.width / w;
		}
		backgroundImage.height = background.height;
		backgroundImage.width = background.width;
		setBackground(background);
		backgroundRef.current && backgroundRef.current.batchDraw();
	}

	function dragBoundFunc(pos) {
		let topLimit = -background.height * 0.9 * scale;
		let bottomLimit = canvas.height * 0.9;
		let leftLimit = -background.width * 0.9 * scale;
		let rightLimit = canvas.width * 0.9;
		let newY, newX;

		if (pos.y < topLimit) newY = topLimit;
		else if (pos.y > bottomLimit) newY = bottomLimit;
		else newY = pos.y;
		if (pos.x < leftLimit) newX = leftLimit;
		else if (pos.x > rightLimit) newX = rightLimit;
		else newX = pos.x;

		return {
			x: newX,
			y: newY
		};
	}

	if (!canvas) return <div/>;

	return (
		<Stage
			key={canvas.width +"-"+ canvas.height}
			ref={refElem}
			width={canvas.width}
			height={canvas.height}
			onMouseMove={() => {
				const cursorP = findPositionOnMap(background.ratio)
				setCursorPosition(cursorP)
			}}
			draggable
			dragBoundFunc={pos => dragBoundFunc(pos)}
			onWheel={onWheel}
			onMouseDown={() => {
				let newElement = {
					map : map,
					...findPositionOnMap(background.ratio)
				}
				onBackgroundClick(newElement);
				setSelected({});
			}}
		>
			<Layer ref={backgroundRef}>
				<URLImage
					src={mapImage}
					onLoad={imageRef => setBackgroundImage(imageRef)}
				/>
			</Layer>
			{Object.keys(background).length !== 0 && markers &&
			<Layer ref={markerLayer}>
				{markers.map(m =>
					<MarkerImage
						selected={selected.marker === m.id}
						marker={m}
						key={m.id}
						ratio={background.ratio}
						scale={scale}
						onClick={e => {
							onMarkerClick(e);
							selected.marker === m.id ? setSelected({}) : setSelected({marker: m.id});
						}}
					/>
				)}
			</Layer>
			}
			{Object.keys(background).length !== 0 && nuvapSensors &&
				<Layer ref={nuvapSensorsLayer}>
					{nuvapSensors.map(ns =>
						<SensorImage
							sensor={ns}
							ratio={background.ratio}
							scale={scale}
							onClick={e => onNuvapSensorClick(e)}
						/>
					)}
				</Layer>
			}
		</Stage>
	);
}

IndoorMap.propTypes =
	{
		markers: PropTypes.array,
		positions: PropTypes.array,
		containerWidth: PropTypes.number,
		mapImage: PropTypes.string.isRequired,
		scale: PropTypes.number,
		refElem: PropTypes.object,
		onMarkerClick: PropTypes.func,
		onBackgroundClick: PropTypes.func
	};

