import React,{useEffect,useRef,useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Grid} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        top: "-60%",
        width: "24rem",
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        padding: "1rem",
        zIndex: 999,
    }
}));

export default function BasePopup ({popupPosition, header, children}) {

    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    const classes = useStyles()

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <div ref={ref} className={classes.root} style={{
            left : popupPosition === "left"? "0" : "65%",
            marginBottom : -(height)
        }}>
            {header}
            <Grid>
                {children}
            </Grid>
        </div>
    );
}