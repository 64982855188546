import React from "react";
import {Arc, Circle, Text} from "react-konva";

export default function SensorImage({sensor, ratio, scale, onClick}) {

	return <>
        <Circle
			x={sensor.x * ratio}
			y={sensor.y * ratio}
			radius={sensor.selected ? 27 / scale : 23 / scale}
			strokeWidth={sensor.selected ? 6.7 / scale : 4.7 / scale}
			stroke="#D8D8D8"
			onClick={() => onClick(sensor.deviceId)}
		/>
		<Arc
			x={sensor.x * ratio}
			y={sensor.y * ratio}
			innerRadius={sensor.selected ? 27 / scale : 23 / scale}
			outerRadius={sensor.selected ? 27 / scale : 23 / scale}
			angle={360 * sensor.nuvapIndex / 10}
			rotationDeg={-90}
			strokeWidth={sensor.selected ? 7 / scale : 5 / scale}
			stroke={sensor.selected ? "#4182fc" : "#1CA8F8"}
			onClick={() => onClick(sensor.deviceId)}
		/>
		<Text
			x={sensor.x * ratio - 14 / scale}
			y={sensor.y * ratio - 8 / scale}
			text={(Math.round(sensor.nuvapIndex * 10) / 10).toFixed(1)}
			fontSize={20 / scale}
			fontStyle="bold"
			onClick={() => onClick(sensor.deviceId)}
		/>
	</>
}