import {useState} from "react";
import {download,get} from "./Client";
import {useQuery} from "react-query";
import {mapId} from "../constants/venue";

export function useGetMapImage() {
    const [image, setImage] = useState(null)

    const {status, error} = useQuery(
        ["mapImage"],
        () => {
            download(`maps/${mapId}/image`).then(response => setImage(URL.createObjectURL(response.data)))
        },
        {staleTime: Infinity})
    return {status, image, error};
}

export function useGetNuvapAnalytics() {
    const {status, data, dataUpdatedAt, error} = useQuery(
        ['NuvapAnalytics'],
        () => get(`nuvap/analytics`, {params: {mapId: mapId}}),
        {refetchInterval: 5*60*1000}
    );
    return {status, nuvapAnalytics: data || [], nuvapAnalyticsUpdatedAt: dataUpdatedAt, error};
}

export function useGetPoi() {
    const {status, data, error} = useQuery(
        ['poi'],
        () => get(`poi`, {params: {map: mapId}}),
        {staleTime: Infinity}
    );
    return {status, poi: data?.filter(poi => poi.reachable) || [], error};
}

export function useGetNuvapSnapshot() {
    const {status, data, error} = useQuery(
        ['NuvapSnapshot'],
        () => get(`nuvap/measurements/snapshot`, {params: {mapId: mapId}}),
        {refetchInterval: 5*60*1000}
    );
    return {status, snapshot: data || [], error};
}

export function useGetPoiAnalytics(poiId) {
    const {status, data, error} = useQuery(
        ['poiAnalytics', poiId],
        () => get(`poi/${poiId}/analytics`),
    );
    return {status, poiAnalytics: data || {}, error};
}

export function useGetMeasurements(startTimestamp, endTimestamp) {
    const {status, data, error} = useQuery(
        ['measurements', startTimestamp, endTimestamp],
        () => get(`nuvap/measurements`, {params: {mapId: mapId, start: startTimestamp, end: endTimestamp}}),
    );
    return {status, measurements: data ? [...data].reverse() : [], error};
}