import {baseUrl} from "../constants/network";
import {TokenManager} from "./TokenManager";
import axios from 'axios'
let tokenManager = new TokenManager();

export function get(url, config = {elem: "", params: {}, header: {}}) {
    return axios.get(getUrl(url, config.elem), getConfig(config)).then(({data}) => data);
}

export function post(url, config = {elem: "", body: {}, params: {}, header: {}}) {
    return axios.post(getUrl(url, config.elem), config.body, getConfig(config)).then(({data}) => data);
}

export function put(url, config = {elem: "", body: {}, params: {}, header: {}}) {
    return axios.put(getUrl(url, config.elem), config.body, getConfig(config)).then(({data}) => data);
}

export function deleteElem(url, config = {elem: "", body: {}, params: {}, header: {}}) {
    return axios.delete(getUrl(url, config.elem), getConfig(config)).then(({data}) => data);
}

export function download(url) {
    return axios.get(getUrl(url), {responseType: 'blob', ...getConfig({})});
}

export function getErrorMessage(e) {
    let error = "Unknown error";
    if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        error = e.response.data.error;
    } else {
        // Something happened in setting up the request that triggered an Error
        error = e.message || e.error;
    }
    console.log("ErrorMessage ", error);
    return error;
}

function getConfig({params = {}, headers = {}}) {
    let token = tokenManager.get();
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return {
        params: params,
        headers
    };
}

function getUrl(url, elem) {
    return elem ? `${baseUrl}${url}/${elem}` : `${baseUrl}${url}`;
}