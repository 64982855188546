import React,{Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import App from './App';
import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import './i18n';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
          <Suspense fallback="Loading...">
              <Router>
                <App/>
              </Router>
          </Suspense>
      </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();