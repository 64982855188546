import {Grid, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import dayjs from "dayjs";
import {useGetNuvapAnalytics} from "../services/ContentManager";

export default function Cards() {

    let {nuvapAnalytics, nuvapAnalyticsUpdatedAt} = useGetNuvapAnalytics();

    return <Grid container spacing={4}>
        <Grid item xs={3}>
            <BlackWhiteCard title="TOTALE VISITATORI">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography style={{fontWeight : 'bold'}} variant="h3">112</Typography>
                    </Grid>
                    <Grid item>
                        VISITATORI
                    </Grid>
                </Grid>
            </BlackWhiteCard>
        </Grid>
        <Grid item xs={4.5}>
            <BlackWhiteCard title="VISITATORI IN REAL TIME">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        {dayjs(new Date(), "x").format("DD/MM/YY [H]HH:mm")}
                    </Grid>
                    <Grid item>
                        <Typography style={{fontWeight : 'bold'}} variant="h3">48</Typography>
                    </Grid>
                    <Grid item>
                        VISITATORI
                    </Grid>
                </Grid>
            </BlackWhiteCard>
        </Grid>
        <Grid item xs={4.5}>
            <BlackWhiteCard title="TEMPO MEDIO DI PERMANENZA">
                <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                    <Grid item><Typography style={{fontWeight : 'bold'}} variant="h3">
                        2
                    </Typography></Grid>
                    <Grid item><Typography style={{fontWeight : 'bold', padding: "4px"}} variant="h5">
                        {Math.floor(nuvapAnalytics.averageVisitTime/60/60) === "1" ? "ora" : "ore"}
                    </Typography></Grid>
                    <Grid item><Typography style={{fontWeight : 'bold'}} variant="h3">
                        18
                    </Typography></Grid>
                    <Grid item><Typography style={{fontWeight : 'bold', padding: "4px"}} variant="h5">
                        {Math.floor(nuvapAnalytics.averageVisitTime/60)%60 === "1" ? "minuto" : "minuti"}
                    </Typography></Grid>
                </Grid>
            </BlackWhiteCard>
        </Grid>
    </Grid>
}

const useStyles = makeStyles((theme) => ( {
    card : {
        height: "12rem",
        borderRadius: "16px"
    },
    cardHeader: {
        backgroundColor: "#000",
        color: "#fff",
        "border-top-left-radius": "16px",
        "border-top-right-radius": "16px"
    }
}));

function BlackWhiteCard({title, children}){

    const classes = useStyles();

    return <Card className={classes.card}>
        <CardHeader
            className={classes.cardHeader}
            title={title}
            style={{ textAlign: 'center' }}
            titleTypographyProps={{variant: "body1", fontWeight: 'bold'}}
        />
        <CardContent>
            {children}
        </CardContent>
    </Card>

}